<template>
  <container>
    <page-header>
      <grid>
        <grid-column eight>
          <h1>Activity</h1>
        </grid-column>
      </grid>
    </page-header>

    <grid class="activity-grid">
      <grid-column ten class="top-row">
        <flex align="center">
          <date-picker
            icon
            :first-day-of-week="1"
            :date="startDate"
            @update="setDate"
          />
          <span v-if="startDate" class="date">
            {{ formatDate(startDate) }}
            <i class="small grey arrow right icon" />
            {{ formatDate(displayedEndDate) }}
          </span>
        </flex>
      </grid-column>
      <grid-column six right-aligned class="top-row">
        <btn
          variant="default"
          basic
          size="small"
          class="current-week-btn mobile hidden"
          :disabled="daysFromStart > -7 && daysFromEnd < 7"
          @click.native="goToCurrentWeek"
        >
          This Week
        </btn>
        <btn
          compact
          size="large"
          icon="fas fa-angle-left"
          tooltip="Previous Week"
          @click.native="goToPreviousWeek"
        />

        <btn
          compact
          size="large"
          icon="fas fa-angle-right"
          tooltip="Next Week"
          @click.native="goToNextWeek"
        />
      </grid-column>

      <loading v-if="loading" :loading="loading" />
      <template v-else>
        <grid-column
          v-for="date in dateRange"
          :key="date.toString()"
          class="day-row"
          sixteen
        >
          <grid stackable equal-width doubling>
            <grid-column
              three
              :class="[
                {
                  today: isToday(date),
                  'weekend-background': isWeekendBumping(date),
                },
              ]"
            >
              <div class="date">
                {{ format(date, 'EEEE') }}
              </div>
              <div>
                {{ formatDate(date) }}
              </div>
            </grid-column>
            <grid-column
              v-if="isWeekendBumping(date)"
              class="quiet-day-background quiet-day-text"
            >
              💤 You have weekend bumping on so we've moved today's messages to
              Friday
            </grid-column>
            <grid-column
              v-else-if="isQuietDay(date)"
              class="quiet-day-background quiet-day-text"
            >
              🤫 You selected today as a quiet day so we've moved today's
              messages to the previous day
            </grid-column>
            <grid-column v-else class="day-row-grid">
              <grid
                v-if="filterUserActionsByDate(date).length"
                equal-width
                class="inner-grid"
              >
                <template v-for="userAction in filterUserActionsByDate(date)">
                  <grid-column
                    :key="userAction.id"
                    sixteen
                    class="pointer feed-row feed-row-border"
                  >
                    <grid stackable>
                      <grid-column
                        class="feed-item-description"
                        ten
                        @click.native="() => togglePreview(userAction.id)"
                      >
                        <flex align="baseline">
                          <user-action-status-indicator
                            :status="getStatusForUserAction(userAction)"
                            :action-type="userAction.action.actionType"
                            :percentage-complete="
                              percentageComplete(userAction)
                            "
                            class="mobile hidden"
                          />
                          <span class="time">{{
                            formattedTime(userAction)
                          }}</span>
                          <span class="message">
                            <p
                              v-html="
                                `${specificActionPhrase(userAction)}
                              <strong>${recipientName(
                                userAction
                              )}</strong> ${enrolleePhrase(userAction)}
                              ${descriptionDetails(userAction)}`
                              "
                            />
                            <p
                              v-if="
                                getStatusForUserAction(userAction) === 'error'
                              "
                              v-html="getErrorMessageForUserAction(userAction)"
                            />
                          </span>
                        </flex>
                      </grid-column>
                      <grid-column class="mobile hidden" six>
                        <router-link
                          :to="{
                            name: 'moment',
                            params: { id: userAction.action.moment.id },
                          }"
                        >
                          <flex align="baseline" class="moment-container">
                            <moment-icon
                              :hex-color="userAction.action.moment.color"
                              :icon="userAction.action.moment.icon"
                              size="medium"
                            />
                            <div class="moment-name">
                              {{ userAction.action.moment.name }}
                            </div>
                          </flex>
                        </router-link>
                      </grid-column>
                    </grid>
                  </grid-column>
                  <transition :key="`${userAction.id}-preview`" name="preview">
                    <grid-column
                      v-if="messagePreviewVisible.get(userAction.id)"
                      sixteen
                      class="feed-row-expansion"
                      @click.native="() => hidePreview(userAction.id)"
                    >
                      <div
                        v-if="userAction.action.actionType === 'email'"
                        class="email-preview"
                        v-html="removeMention(userAction.compiledMessageHtml)"
                      />
                      <meeting-preview
                        v-else-if="userAction.action.actionType === 'meeting'"
                        :user-action="userAction"
                        :user-actions="userActions"
                        :action="userAction.action"
                        :organization="organization"
                      >
                        <template #title>
                          <span
                            v-html="
                              removeMention(userAction.compiledMeetingTitle)
                            "
                          />
                        </template>
                        <template #body>
                          <span
                            v-html="
                              removeMention(userAction.compiledMessageHtml)
                            "
                          />
                        </template>
                      </meeting-preview>
                      <message-preview
                        v-else
                        :message-time="userAction.action.relativeTime"
                        :button-cta="userAction.action.buttonName"
                        :button-url="userAction.action.buttonUrl"
                        :tasks="userAction.action.tasks"
                      >
                        <span
                          v-html="removeMention(userAction.compiledMessageHtml)"
                        />
                      </message-preview>
                    </grid-column>
                  </transition>
                </template>
              </grid>
              <grid v-else class="inner-grid empty">
                <grid-column v-once class="feed-row">
                  <span>
                    {{
                      noActivityEmojis[random(0, noActivityEmojis.length - 1)]
                    }}
                  </span>
                  <span>
                    {{
                      noActivityMessages[format(date, 'EEEE')][
                        random(
                          0,
                          noActivityMessages[format(date, 'EEEE')].length - 1
                        )
                      ]
                    }}
                  </span>
                </grid-column>
              </grid>
            </grid-column>
          </grid>
        </grid-column>
      </template>
    </grid>
  </container>
</template>

<script>
import {
  add,
  startOfWeek,
  isSameDay,
  differenceInDays,
  format,
  isToday,
  isWeekend,
  formatISO,
} from 'date-fns'
import { sortBy, random } from 'lodash'

import { api } from '@/api'
import { toast } from '@/toasts'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import datePicker from '@/components/v2/date_picker.vue'
import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'
import momentIcon from '@/components/v2/moment_icon.vue'

import userActionStatusIndicator from '@/components/UserActionStatusIndicator.vue'
import messagePreview from '@/components/MessagePreview.vue'
import meetingPreview from '@/components/meeting_preview.vue'

import dateMixin from '@/mixins/dateMixin'
import humanReadableStringMixin from '@/mixins/humanReadableStringMixin'
import actionStatusMixin from '@/mixins/actionStatusMixin'

export default {
  components: {
    loading,
    container,
    pageHeader,
    grid,
    gridColumn,
    datePicker,
    btn,
    flex,
    momentIcon,
    userActionStatusIndicator,
    messagePreview,
    meetingPreview,
  },
  mixins: [dateMixin, humanReadableStringMixin, actionStatusMixin],
  data() {
    return {
      format,
      isToday,
      random,
      userActions: [],
      quietDays: [],
      organization: {},
      startDate: null,
      endDate: null,
      loading: true,
      displayedEndDate: null,
      messagePreviewVisible: new Map(),
      noActivityEmojis: [
        '🏝',
        '🏕',
        '😎',
        '☀️',
        '⭐️',
        '🌴',
        '🛬',
        '⛵️',
        '🌁',
        '🧘',
        '👋',
      ],
      noActivityMessages: {
        Monday: [
          'An easy start to the week',
          'Just think of it like a long weekend',
          'A nice quiet day',
          'Is today a holiday?',
        ],
        Tuesday: [
          'Nothing to see here',
          "It's like an extra day of PTO",
          'Just chilling',
          'No gathering happening today',
        ],
        Wednesday: [
          'Who let the dogs out?',
          'All quiet on the Gather front today',
          "Whoaaa, we're halfway there!",
          'Free time is a terrible thing to waste',
        ],
        Thursday: [
          'No updates for today',
          'You now have permission to take the day off',
          'No gathering happening today',
        ],
        Friday: [
          'Nothing happening',
          "It's basically the weekend",
          'Is today a holiday?',
          'Free time is a terrible thing to waste',
        ],
        Saturday: [
          "Party like it's the weekend",
          'Quiet weekend',
          'Chilling on the weekend',
          'See you on Monday',
        ],
        Sunday: [
          'Lazy Sunday',
          'Weekends are not for working',
          'Nothing to see here',
          'Taking the weekend off',
        ],
      },
    }
  },
  computed: {
    dateRange() {
      const dateArray = []
      const numberOfDays = differenceInDays(this.endDate, this.startDate)
      for (let n = 0; n < numberOfDays; n += 1) {
        dateArray.push(add(this.startDate, { days: n }))
      }
      return dateArray
    },
    daysFromStart() {
      return differenceInDays(this.startDate, new Date())
    },
    daysFromEnd() {
      return differenceInDays(this.endDate, new Date())
    },
  },
  watch: {
    endDate: {
      /**
       * the displayed end date should be one day less than the date emitted to the database
       * this is because the end date for the db should be the next day at midnight and the day we show to the user
       * should be the previous day at 11:59:59pm
       */
      handler(newDate) {
        this.displayedEndDate = add(newDate, { days: -1 })
      },
    },
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.loading = true
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
        await this.setCalendarActivityRange(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          add(startOfWeek(new Date(), { weekStartsOn: 1 }), { days: 7 })
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
    },
    async setCalendarActivityRange(startDate, endDate) {
      if (
        isSameDay(this.startDate, startDate) &&
        isSameDay(this.endDate, endDate)
      ) {
        return
      }

      try {
        this.loading = true
        this.startDate = startDate
        this.endDate = endDate
        this.userActions = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/userActions`,
          {
            organizationId: this.$store.state.organizationId,
            start: this.startDate,
            end: this.endDate,
          }
        )
        await this.getQuietDays()
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
    },
    async getQuietDays() {
      try {
        this.quietDays = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/quiet-days`, {
            start: formatISO(this.startDate, { representation: 'date' }),
            end: formatISO(this.endDate, { representation: 'date' }),
            sort: 'date,id',
          })
        ).data.map((quietDay) => quietDay.date)
      } catch (error) {
        toast.error(error)
      }
    },
    async setDate(date) {
      if (!date) {
        return
      }
      const newStartDate = startOfWeek(date, { weekStartsOn: 1 })
      await this.setCalendarActivityRange(
        newStartDate,
        add(newStartDate, { days: 7 })
      )
    },
    async goToCurrentWeek() {
      const newStartDate = startOfWeek(new Date(), { weekStartsOn: 1 })
      await this.setCalendarActivityRange(
        newStartDate,
        add(newStartDate, { days: 7 })
      )
    },
    async goToNextWeek() {
      await this.setCalendarActivityRange(
        add(this.startDate, { weeks: 1 }),
        add(this.endDate, { weeks: 1 })
      )
    },
    async goToPreviousWeek() {
      await this.setCalendarActivityRange(
        add(this.startDate, { weeks: -1 }),
        add(this.endDate, { weeks: -1 })
      )
    },
    filterUserActionsByDate(date) {
      return sortBy(
        this.userActions.filter((userAction) =>
          isSameDay(new Date(userAction.actionDate), date)
        ),
        (userAction) => userAction.actionDate
      )
    },
    removeMention(html) {
      return html.replace(/class="(.*?)"/g, '')
    },
    togglePreview(id) {
      this.messagePreviewVisible = new Map(
        this.messagePreviewVisible.set(id, !this.messagePreviewVisible.get(id))
      )
    },
    hidePreview(id) {
      this.messagePreviewVisible = new Map(
        this.messagePreviewVisible.set(id, false)
      )
    },
    formattedTime(userAction) {
      return this.formatTime(new Date(userAction.actionDate), {
        timeZone: this.organization.timezone,
      })
    },
    percentageComplete(userAction) {
      if (userAction.action.actionType === 'task') {
        return this.calculateCompletionPercentageForTasks(userAction.userTasks)
      }
      // fully complete is represented as 1.0
      return 1.0
    },
    recipientName(userAction) {
      // Regardless of actionRecipient return nothing for relationship or channel
      if (
        userAction.action.actionType === 'relationship' ||
        userAction.action.actionType === 'channel'
      ) {
        return ''
      }
      // Recipient Name for a User
      if (userAction.actionRecipient) {
        return this.formatFullName(userAction.actionRecipient)
      }

      // Recipient Name is a Slack Channel
      const recipientSlackChannel = userAction.actionRecipientChannel
      if (recipientSlackChannel) {
        if (recipientSlackChannel.conversationType === 'publicChannel') {
          return `#${recipientSlackChannel.displayName}`
        }
        if (recipientSlackChannel.conversationType === 'privateChannel') {
          return `<i class="icon fas fa-lock"></i>${recipientSlackChannel.displayName}`
        }
      }
      throw new TypeError('Was not able to set recipientName.')
    },
    enrolleePhrase(userAction) {
      if (userAction.action.moment.type !== 'date') {
        return `for <strong> ${this.formatFullName(
          userAction.enrolledUser
        )} </strong>`
      }
      return ''
    },
    descriptionDetails(userAction) {
      if (
        userAction.action.actionType === 'channel' &&
        userAction.channelArchiveDate
      ) {
        return `(Archives on ${this.formatDate(
          new Date(userAction.channelArchiveDate),
          this.organization.timezone
        )})`
      }
      return ''
    },
    specificActionPhrase(userAction) {
      const actionVerb = this.getVerbForAction(userAction.action)
      const specificActionPhrase = this.getSpecificPhraseForAction(
        userAction.action
      )

      if (userAction.action.actionType === 'channel') {
        return userAction.action.isPrivateChannel
          ? `${actionVerb}
          <strong><i class="small icon fas fa-lock"></i>${userAction.action.compiledChannelName}</strong>`
          : `${actionVerb} <strong>#${userAction.action.compiledChannelName}</strong>`
      }

      if (
        userAction.action.actionType === 'message' ||
        userAction.action.actionType === 'task' ||
        userAction.action.actionType === 'email' ||
        userAction.action.actionType === 'meeting'
      ) {
        return `${specificActionPhrase} to`
      }

      if (userAction.action.actionType === 'relationship') {
        return `${actionVerb} <strong>${this.getSpecificNounForAction(
          userAction.action
        )}</strong>`
      }

      return specificActionPhrase
    },
    isWeekendBumping(date) {
      return this.organization.weekendBumping && isWeekend(date)
    },
    isQuietDay(date) {
      return this.quietDays.includes(
        formatISO(date, { representation: 'date' })
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';
@import '~@/assets/less/responsive.less';

.activity-grid {
  margin-bottom: 2em;
  border: @light-border;
  border-radius: @standard-border-radius;
}

.feed-row {
  padding: 0.75em 1em !important; // to override fomantic grid settings
}

.feed-row-border {
  margin-top: -1px;
  border-top: @light-border;
}

.feed-row-expansion {
  padding: 0.5em 1em 2em 1em !important; // to override fomantic grid settings
}

.current-week-btn {
  margin-right: 1em;
}

.day-row {
  border-top: @light-border;
}

.day-row-grid {
  padding-top: 0 !important; // to override fomantic grid settings
  padding-bottom: 0 !important; // to override fomantic grid settings
}

.feed-item-description {
  padding-right: 0 !important; // to override fomantic grid settings
}

.date {
  font-size: @title-font-size;
  font-weight: bold;
}

.quiet-day-background {
  background-color: @light-grey-background;
}

.quiet-day-text {
  padding-top: 1.75em !important;
}

.today {
  background-color: @pink;
}

.inner-grid {
  margin: 0 -1em;

  &.empty {
    margin-top: 1em;
  }
}

.time {
  min-width: 5em;
  margin-left: 1em;
}

.message {
  margin-left: 1em;
}

.moment-name {
  margin-left: 1em;
}

.moment-container {
  border-radius: @standard-border-radius;
  padding: 0.5em 1em 0.5em 0.5em;
  margin: -0.5em 0;
  width: fit-content;
  color: @blue;

  &:hover {
    background-color: @light-grey-hover;
  }
}

.email-preview {
  width: 75%;
  padding: 1rem;
  border: @light-border;
  border-radius: @standard-border-radius;

  ::v-deep img {
    width: 75%;
    height: 100%;
  }
}
// transition
.preview-enter-active {
  max-height: 100em; // HACK: transitions on height need an actual value that can cover large message previews
  overflow: hidden;
  transition: all 0.8s linear;
}

.preview-leave-active {
  max-height: 100em;
  overflow: hidden;
  transition: all 0.4s ease-out;
}

.preview-enter,
.preview-leave-to {
  max-height: 0;
}
</style>
