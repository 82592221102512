<template>
  <flex direction="column" class="preview-outline" gap="1rem">
    <flex direction="row" align="center">
      <i class="grey icon far fa-calendar" />
      <flex direction="column">
        <flex class="meeting-title"><slot name="title" /></flex>
        <flex>
          {{ dayOfWeek }}
          {{ formatDate(parseISO(userAction.meetingStartTime), true) }}
          ⋅
          {{
            formatTime(serializeTimeFromString(action.relativeMeetingStartTime))
          }}
          -
          {{
            formatTime(serializeTimeFromString(action.relativeMeetingEndTime), {
              timeZone: organization.timezone,
            })
          }}
        </flex>
      </flex>
    </flex>
    <flex v-if="action.meetingLocation" direction="row" align="center">
      <i class="grey icon fas fa-map-marker-alt" />
      {{ action.meetingLocation }}
    </flex>
    <flex v-if="action.body" direction="row" align="start">
      <i class="grey icon fas fa-align-justify" />
      <slot name="body" />
    </flex>
  </flex>
</template>

<script>
import { parseISO } from 'date-fns'
import dateMixin from '@/mixins/v2/dateMixin'

import flex from '@/components/v2/flex.vue'

export default {
  components: {
    flex,
  },
  mixins: [dateMixin],
  props: {
    userActions: {
      type: Array,
      default: () => [],
    },
    userAction: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parseISO,
      daysOfWeek: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
    }
  },
  computed: {
    dayOfWeek() {
      return this.daysOfWeek[
        parseISO(this.userAction.meetingStartTime).getDay()
      ]
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';
@import '~@/assets/less/colors.less';
@import '~@/assets/less/message-content.less';

.icon {
  font-size: @big-icon-font-size;
  padding-right: 0.25rem;
}

.preview-outline {
  border: @light-border;
  border-radius: @standard-border-radius;
  width: 100%;
  max-width: 38em;
  padding: 1em;
}

.meeting-title {
  font-size: @title-font-size;
}
</style>
