<template>
  <div class="ui card message-content preview">
    <div class="content no padding">
      <div class="card-small-square left floated">
        <img :src="sender.image && sender.image.src" class="preview-img" />
      </div>
      <div class="message-header">
        <div class="message-sender">
          {{ sender.value }}
        </div>
        <div class="app-badge">APP</div>
        <div class="timestamp">
          {{ messageTime }}
        </div>
      </div>
      <div v-if="hasMessageBody" class="section-block">
        <slot />
      </div>
      <div v-if="!tasks.length" class="section-block">
        <a v-if="buttonUrl && buttonCta" :href="buttonUrl" target="_blank">
          <button class="ui button basic slack-button no-underline-link">
            {{ buttonCta }}
          </button>
        </a>
        <div
          v-if="buttonCta && !buttonUrl"
          class="full"
          @click="showNoUrlAlert"
        >
          <button class="ui button basic slack-button no-underline-link">
            {{ buttonCta }}
          </button>
          <br />
          <small class="red">{{ noUrlError }}</small>
        </div>
      </div>
      <div v-else class="section-block">
        <div v-for="task in tasks" :key="task.id" class="task-section">
          <div class="divider" />
          <p class="task-name message-copy">
            {{ task.name }}
          </p>
          <p class="message-copy scrollable" v-html="task.description" />
          <button
            class="
              ui
              button
              basic
              slack-button
              complete-button
              no-underline-link
            "
          >
            ✅ Mark Complete
          </button>
          <button
            v-if="!task.isRequired"
            class="ui button basic slack-button no-underline-link"
          >
            Skip
          </button>
          <p class="details message-copy task-requirement">
            {{ formatTaskDeadline(task) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gatherAppImage from '@/assets/img/gather_g_small.png'
import defaultProfileImage from '@/assets/img/profile_avatar_small.png'
import dateMixin from '@/mixins/dateMixin'

export default {
  mixins: [dateMixin],
  props: {
    buttonCta: {
      type: String,
      default: '',
    },
    buttonUrl: {
      type: String,
      default: '',
    },
    messageTime: {
      type: String,
      default: '12:00 PM',
    },
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      gatherAppImage,
      defaultProfileImage,
      noUrlError: '',
      sender: {
        text: 'Gather (Bot)',
        value: 'Gather',
        image: { avatar: true, src: gatherAppImage },
      },
    }
  },
  computed: {
    hasMessageBody() {
      return !!this.$slots.default
    },
  },
  methods: {
    showNoUrlAlert() {
      this.noUrlError =
        "Sorry, that button doesn't work here! Try using Test Workflow to see it in action."
      setTimeout(() => {
        this.noUrlError = ''
      }, 3000)
    },
    formatTaskDeadline(task) {
      if (!task.isRequired) {
        return 'Optional'
      }

      return `Required | Deadline: ${this.formatDynamicDate(
        task.deadline,
        null,
        null,
        new Date()
      )}`
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/message-content.less';
@import '~@/assets/less/borders.less';

.ui.card.preview {
  width: 100%;
  max-width: 38em;
  font-family: @slack-font;

  .message-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .content {
    /deep/ p {
      margin: @message-p-margin;
    }

    /deep/ p:empty {
      height: 8px;
    }

    .message-copy {
      margin: 0;
    }

    .scrollable {
      max-height: 15em;
      overflow-y: auto;
    }
  }

  a {
    color: @slack-link;
  }

  .card-small-square {
    margin: 0 8px 0 0;
  }

  .preview-img {
    width: 36px;
    height: 36px;
    border-radius: @slack-in-message-border-radius;
  }

  .message-sender {
    font-size: @slack-font-size;
    font-weight: 700;
    color: @slack-black;
  }

  .app-badge {
    display: inline-block;
    height: 14px;
    padding: 1px 3px;
    margin: 0 4px 0 4px;
    font-size: @slack-app-badge-font-size;
    font-weight: 700;
    line-height: 1.25;
    color: @slack-text-grey;
    vertical-align: 0.125rem;
    background-color: @slack-light-grey;
    border-radius: @slack-app-badge-border-radius;
  }

  .timestamp {
    padding-left: 4px;
    font-size: @description-font-size;
    color: @slack-grey;
  }

  .section-block {
    display: flex;
    margin: 0 0 0 44px;
    overflow: hidden;
    font-size: @slack-font-size;
    font-weight: 400;
    line-height: 1.46668;
    color: @slack-black;

    .full {
      width: 100%;
    }
  }

  .ui.button.slack-button {
    min-width: 56px;
    height: 28px;
    padding: 0 12px 1px;
    margin: 4px 10px 0 0;
    font-family: @slack-font;
    font-size: @slack-button-font-size;
    font-weight: 700;
    color: @slack-black;
    cursor: pointer;
    user-select: none;
    background-color: @white;
    border: @slack-button-border;
    border-radius: @slack-button-border-radius;
    outline: none;
    transition: all 80ms linear;
  }

  .ui.button.slack-button:hover {
    background: @slack-hover-white;
  }

  .ui.button.slack-button.complete-button {
    color: @green;
    border: @slack-green-button-border;
  }

  .task-section {
    display: inline-block;
    width: 100%;

    .divider {
      width: 100%;
      margin: 4px auto 8px;
      border-bottom: @slack-button-border;
    }

    .task-name {
      font-weight: bold;
    }

    .task-requirement {
      font-weight: bold;
    }
  }

  .details {
    padding: 0.5em 0 0 0;
    font-size: @description-font-size;
    color: @slack-grey;
  }
}

@media only screen and (max-width: 768px) {
  .ui.card {
    width: 100%;
  }
}

.red {
  color: @slack-red;
  transition: 0.5s;
}
</style>
