// ================================================================
// Purpose: encapsulate all user-facing logic that determines
// what the "status" of a given userAction is. These statuses are used
// to display informative icons and messages to the user in-app
// ================================================================
import { add, set, startOfDay, formatDistanceToNowStrict } from 'date-fns'
import dateMixin from '@/mixins/dateMixin'
import humanReadableStringMixin from '@/mixins/humanReadableStringMixin'

/**
 * @param {Array} userActions
 * @returns {boolean} - do any user tasks exist for a given list of user actions
 */
function hasUserTask(userActions) {
  return userActions.some((userAction) => userAction.userTasks.length)
}

/**
 * @param {Array} userActions
 * @returns {boolean} - do any relationship assignments exist for a given list of user actions
 */
function hasRelationshipAssignment(userActions) {
  return userActions.some((userAction) => !!userAction.relationshipAssignment)
}

/**
 * @param {Array} userActions
 * @returns {boolean} - are all user tasks marked done?
 */
function isAllUserTasksDone(userActions) {
  return userActions.every((userAction) =>
    userAction.userTasks.every((userTask) => userTask.isComplete)
  )
}

/**
 * @param {Array} userActions
 * @returns {boolean} - are all user tasks marked skipped?
 */
function isAllUserTasksSkipped(userActions) {
  return userActions.every((userAction) =>
    userAction.userTasks.every((userTask) => userTask.isSkipped)
  )
}
/**
 * @param {Array} userActions
 * @returns {boolean} - whether or not all user tasks are marked optional
 */
function isAllUserTasksOptional(userActions) {
  return userActions.every((userAction) =>
    userAction.userTasks.every((userTask) => !userTask.deadline)
  )
}

/**
 * @param {Array} userActions
 * @returns {boolean} - do all user actions have a relationshipAssignment?
 */
function isAllUserActionForAssignRelationshipDone(userActions) {
  return userActions.every((userAction) => !!userAction.relationshipAssignment)
}
/**
 * @param {Array} userActions
 * @returns {object|null} - return a user action with an error
 */
function findAnyUserActionsWithError(userActions) {
  return userActions.find(
    (userAction) =>
      (userAction.message || '').toLowerCase().includes('error:') ||
      userAction.actionStatus === 'error'
  )
}
/**
 * are all user actions in this collection marked done
 *
 * @param {Array} userActions - object with userActions array
 * @returns {boolean} - are all user actions marked done?
 */
function isAllUserActionsDone(userActions) {
  return userActions.every((userAction) => userAction.actionStatus === 'done')
}

/**
 * @param {object} collection - user actions and associated action
 * @param {object} collection.action
 * @param {object} user
 * @param {Date} triggerDate
 * @returns {boolean} - was user enrolled after action send date
 */
function isEnrolledAfterActionShouldSend({ action }, user, triggerDate) {
  if (!user || !triggerDate) {
    return false
  }

  const enrolledDate = startOfDay(new Date(user.userMoment.createdAt))

  // add back the offset so that the ISO date is the right day (ignores timezone)
  const triggerDateAddOffset = add(triggerDate, {
    minutes: triggerDate.getTimezoneOffset(),
  })

  // roughly calculate assumed user action date
  const [hours, minutes] = action.relativeTime.split(':')
  const userActionDate = set(
    add(
      triggerDateAddOffset,
      dateMixin.methods.parseDuration(action.relativeDate)
    ),
    {
      hours: Number(hours),
      minutes: Number(minutes),
    }
  )

  // if after no adjustment this is already true, then user was not enrolled after trigger date
  if (enrolledDate < userActionDate) {
    return false
  }

  // change year if action date is in past
  // year calculation handles Jan/Dec boundary
  const userActionDateWithAdjustedYear = set(userActionDate, {
    year:
      new Date().getFullYear() -
      (triggerDate.getFullYear() - userActionDate.getFullYear()),
  })

  return enrolledDate > userActionDateWithAdjustedYear
}

/**
 * @param {object} userAction
 * @returns {string} - action recipient name
 */
function actionRecipientName(userAction) {
  if (userAction.actionRecipient) {
    return humanReadableStringMixin.methods.formatFullName(
      userAction.actionRecipient
    )
  }
  if (userAction.actionRecipientChannel) {
    return userAction.actionRecipientChannel.displayName
  }
  throw new Error(`User Action ${userAction.id} has no recipient`)
}

/**
 * @param {Array} userActions
 * @returns {boolean} - are any user tasks overdue
 */
function isAnyUserTaskOverdue(userActions) {
  if (
    hasUserTask(userActions) &&
    (isAllUserTasksDone(userActions) ||
      isAllUserTasksSkipped(userActions) ||
      isAllUserTasksOptional(userActions))
  ) {
    return false
  }
  return userActions.some((userAction) =>
    userAction.userTasks.some(
      (userTask) =>
        userTask.deadline !== null && new Date() > new Date(userTask.deadline)
    )
  )
}

export default {
  methods: {
    /**
     * @param {Array} userActions
     * @returns {boolean} - are any user tasks marked done?
     */
    isAnyUserTaskDone(userActions) {
      return userActions.some((userAction) =>
        userAction.userTasks.some((userTask) => userTask.isComplete)
      )
    },
    /**
     * get status for a single user action
     * puts user action in an array to reuse same functions
     *
     * @param {object} userAction
     * @returns {string} - status
     */
    getStatusForUserAction(userAction) {
      if (userAction.action.actionType === 'task') {
        if (hasUserTask([userAction]) && isAllUserTasksDone([userAction])) {
          return 'complete'
        }
        if (this.isAnyUserTaskDone([userAction])) {
          return 'partialComplete'
        }
        if (!hasUserTask([userAction]) && userAction.actionStatus === 'done') {
          return 'error'
        }
      }

      if (userAction.action.actionType === 'relationship') {
        if (
          hasRelationshipAssignment([userAction]) &&
          isAllUserActionForAssignRelationshipDone([userAction])
        ) {
          return 'complete'
        }
      }

      if (findAnyUserActionsWithError([userAction])) {
        return 'error'
      }

      if (userAction.actionStatus === 'done') {
        return 'sent'
      }

      return 'scheduled'
    },
    /**
     * get status of user actions
     * collection is a userActions/action pair
     *
     * @param {Array} collection
     * @param {Array} collection.userActions
     * @param {object} collection.action
     * @param {object} user
     * @param {Date} triggerDate
     * @returns {string} - status for user action indicator
     */
    getStatusForUserActions({ userActions, action }, user, triggerDate) {
      if (action.actionType === 'task') {
        if (hasUserTask(userActions) && isAllUserTasksDone(userActions)) {
          return 'complete'
        }
        if (this.isAnyUserTaskDone(userActions)) {
          return 'partialComplete'
        }
        if (hasUserTask(userActions) && isAllUserTasksSkipped(userActions)) {
          return 'skipped'
        }
        if (isAnyUserTaskOverdue(userActions)) {
          return 'overdue'
        }
        if (
          userActions.length &&
          !hasUserTask(userActions) &&
          isAllUserActionsDone(userActions)
        ) {
          return 'error'
        }
      }

      if (action.actionType === 'relationship') {
        if (
          hasRelationshipAssignment(userActions) &&
          isAllUserActionForAssignRelationshipDone(userActions)
        ) {
          return 'complete'
        }
      }

      if (userActions.length && isAllUserActionsDone(userActions)) {
        return 'sent'
      }

      if (findAnyUserActionsWithError(userActions)) {
        return 'error'
      }

      if (!userActions.length) {
        if (isEnrolledAfterActionShouldSend({ action }, user, triggerDate)) {
          return 'neverSent'
        }
        return 'error'
      }

      return 'scheduled'
    },
    /**
     * percentage of user tasks that have been marked done
     *
     * @param {Array} userTasks
     * @returns {number} - represents percentage of completed user tasks
     */
    calculateCompletionPercentageForTasks(userTasks) {
      const completedUserTasks = userTasks.filter(
        (userTask) => userTask.isComplete
      )
      // get percentage as float and convert NaN to 0 to cover 0/0 case
      return (completedUserTasks.length * 1.0) / userTasks.length || 0
    },
    /**
     * assumes that the status has already been determined as error
     *
     * @param {object} userAction
     * @returns {string} - error message
     */
    getErrorMessageForUserAction(userAction) {
      if (!userAction) {
        return "We can't find this message."
      }

      if (
        (userAction.message || '').includes(
          'An API error occurred: channel_not_found'
        )
      ) {
        return (
          `(${this.convertRecipientListToReadableString(
            userAction.action.recipients || []
          )}) ` +
          `${actionRecipientName(userAction)}'s email ` +
          'in Gather does not match their email in Slack.'
        )
      }

      if (
        (userAction.message || '').includes(
          'An API error occurred: restricted_action'
        )
      ) {
        return "Gather doesn't have permission to do this. Contact your Slack administrator."
      }

      if (
        (userAction.message || '').includes('An API error occurred: not_authed')
      ) {
        return 'The Gather Slack app has been removed from your workspace. Contact your Slack administrator.'
      }

      if ((userAction.message || '').includes('An API error occurred:')) {
        return 'Looks like something is wrong with the Gather Slack app. Contact the Gather team.'
      }

      if (
        (userAction.message || '').includes(
          'An HTTP protocol error occurred: statusCode = 503'
        )
      ) {
        return "Slack might not be working right now. We'll try again later."
      }

      if (
        (userAction.message || '').includes(
          'No Slack Conversation Id For Recipient'
        )
      ) {
        return `(${this.convertRecipientListToReadableString(
          userAction.action.recipients || []
        )}) We can't find ${actionRecipientName(userAction)} in Slack.`
      }

      // task errors can be due to a few different reasons, so if status returned error and the action is type "task"
      // show generic error
      if (userAction.action.actionType === 'task') {
        return 'Looks like something is wrong with your tasks. Contact the Gather team.'
      }

      return 'Contact the Gather team.'
    },
    /**
     * assumes that the status has already been determined as error
     *
     * @param {Array} collection
     * @param {Array} collection.userActions
     * @param {Date} triggerDate
     * @returns {string} - error message
     */
    getErrorMessageForUserActions({ userActions }, triggerDate) {
      if (!triggerDate) {
        return 'The enrollee for this action is missing a trigger date. You can add one on their profile.'
      }
      // we don't schedule anything with a delta greater than 90 days from now
      if (
        parseInt(
          formatDistanceToNowStrict(
            set(triggerDate, { year: new Date().getFullYear() }),
            { unit: 'day' }
          ),
          10
        ) > 90
      ) {
        return 'This trigger date has past.'
      }

      if (!userActions.length) {
        return 'We were not able to schedule this message. Check again in 30 minutes or contact help@teamgather.co.'
      }
      return this.getErrorMessageForUserAction(
        findAnyUserActionsWithError(userActions)
      )
    },
  },
}
