var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container',[_c('page-header',[_c('grid',[_c('grid-column',{attrs:{"eight":""}},[_c('h1',[_vm._v("Activity")])])],1)],1),_c('grid',{staticClass:"activity-grid"},[_c('grid-column',{staticClass:"top-row",attrs:{"ten":""}},[_c('flex',{attrs:{"align":"center"}},[_c('date-picker',{attrs:{"icon":"","first-day-of-week":1,"date":_vm.startDate},on:{"update":_vm.setDate}}),(_vm.startDate)?_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.startDate))+" "),_c('i',{staticClass:"small grey arrow right icon"}),_vm._v(" "+_vm._s(_vm.formatDate(_vm.displayedEndDate))+" ")]):_vm._e()],1)],1),_c('grid-column',{staticClass:"top-row",attrs:{"six":"","right-aligned":""}},[_c('btn',{staticClass:"current-week-btn mobile hidden",attrs:{"variant":"default","basic":"","size":"small","disabled":_vm.daysFromStart > -7 && _vm.daysFromEnd < 7},nativeOn:{"click":function($event){return _vm.goToCurrentWeek.apply(null, arguments)}}},[_vm._v(" This Week ")]),_c('btn',{attrs:{"compact":"","size":"large","icon":"fas fa-angle-left","tooltip":"Previous Week"},nativeOn:{"click":function($event){return _vm.goToPreviousWeek.apply(null, arguments)}}}),_c('btn',{attrs:{"compact":"","size":"large","icon":"fas fa-angle-right","tooltip":"Next Week"},nativeOn:{"click":function($event){return _vm.goToNextWeek.apply(null, arguments)}}})],1),(_vm.loading)?_c('loading',{attrs:{"loading":_vm.loading}}):_vm._l((_vm.dateRange),function(date){return _c('grid-column',{key:date.toString(),staticClass:"day-row",attrs:{"sixteen":""}},[_c('grid',{attrs:{"stackable":"","equal-width":"","doubling":""}},[_c('grid-column',{class:[
              {
                today: _vm.isToday(date),
                'weekend-background': _vm.isWeekendBumping(date),
              } ],attrs:{"three":""}},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.format(date, 'EEEE'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(date))+" ")])]),(_vm.isWeekendBumping(date))?_c('grid-column',{staticClass:"quiet-day-background quiet-day-text"},[_vm._v(" 💤 You have weekend bumping on so we've moved today's messages to Friday ")]):(_vm.isQuietDay(date))?_c('grid-column',{staticClass:"quiet-day-background quiet-day-text"},[_vm._v(" 🤫 You selected today as a quiet day so we've moved today's messages to the previous day ")]):_c('grid-column',{staticClass:"day-row-grid"},[(_vm.filterUserActionsByDate(date).length)?_c('grid',{staticClass:"inner-grid",attrs:{"equal-width":""}},[_vm._l((_vm.filterUserActionsByDate(date)),function(userAction){return [_c('grid-column',{key:userAction.id,staticClass:"pointer feed-row feed-row-border",attrs:{"sixteen":""}},[_c('grid',{attrs:{"stackable":""}},[_c('grid-column',{staticClass:"feed-item-description",attrs:{"ten":""},nativeOn:{"click":function($event){return (function () { return _vm.togglePreview(userAction.id); }).apply(null, arguments)}}},[_c('flex',{attrs:{"align":"baseline"}},[_c('user-action-status-indicator',{staticClass:"mobile hidden",attrs:{"status":_vm.getStatusForUserAction(userAction),"action-type":userAction.action.actionType,"percentage-complete":_vm.percentageComplete(userAction)}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formattedTime(userAction)))]),_c('span',{staticClass:"message"},[_c('p',{domProps:{"innerHTML":_vm._s(
                              ((_vm.specificActionPhrase(userAction)) + "\n                            <strong>" + (_vm.recipientName(
                              userAction
                            )) + "</strong> " + (_vm.enrolleePhrase(userAction)) + "\n                            " + (_vm.descriptionDetails(userAction)))
                            )}}),(
                              _vm.getStatusForUserAction(userAction) === 'error'
                            )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.getErrorMessageForUserAction(userAction))}}):_vm._e()])],1)],1),_c('grid-column',{staticClass:"mobile hidden",attrs:{"six":""}},[_c('router-link',{attrs:{"to":{
                          name: 'moment',
                          params: { id: userAction.action.moment.id },
                        }}},[_c('flex',{staticClass:"moment-container",attrs:{"align":"baseline"}},[_c('moment-icon',{attrs:{"hex-color":userAction.action.moment.color,"icon":userAction.action.moment.icon,"size":"medium"}}),_c('div',{staticClass:"moment-name"},[_vm._v(" "+_vm._s(userAction.action.moment.name)+" ")])],1)],1)],1)],1)],1),_c('transition',{key:((userAction.id) + "-preview"),attrs:{"name":"preview"}},[(_vm.messagePreviewVisible.get(userAction.id))?_c('grid-column',{staticClass:"feed-row-expansion",attrs:{"sixteen":""},nativeOn:{"click":function($event){return (function () { return _vm.hidePreview(userAction.id); }).apply(null, arguments)}}},[(userAction.action.actionType === 'email')?_c('div',{staticClass:"email-preview",domProps:{"innerHTML":_vm._s(_vm.removeMention(userAction.compiledMessageHtml))}}):(userAction.action.actionType === 'meeting')?_c('meeting-preview',{attrs:{"user-action":userAction,"user-actions":_vm.userActions,"action":userAction.action,"organization":_vm.organization},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.removeMention(userAction.compiledMeetingTitle)
                          )}})]},proxy:true},{key:"body",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.removeMention(userAction.compiledMessageHtml)
                          )}})]},proxy:true}],null,true)}):_c('message-preview',{attrs:{"message-time":userAction.action.relativeTime,"button-cta":userAction.action.buttonName,"button-url":userAction.action.buttonUrl,"tasks":userAction.action.tasks}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.removeMention(userAction.compiledMessageHtml))}})])],1):_vm._e()],1)]})],2):_c('grid',{staticClass:"inner-grid empty"},[_vm._o(_c('grid-column',{staticClass:"feed-row"},[_c('span',[_vm._v(" "+_vm._s(_vm.noActivityEmojis[_vm.random(0, _vm.noActivityEmojis.length - 1)])+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.noActivityMessages[_vm.format(date, 'EEEE')][ _vm.random( 0, _vm.noActivityMessages[_vm.format(date, 'EEEE')].length - 1 ) ])+" ")])]),0,date.toString())],1)],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }